// supabaseClient.js
import { createClient } from "@supabase/supabase-js";
import { SUPABASE_CONFIG } from "./config";
import { removeDuplicateSpaces } from "./utils";

export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

export const callSupabaseFunction = async (functionName, params) => {
  try {
    const { data, error } = await supabase.rpc(functionName, params);

    if (error) {
      console.error("Error calling function:", error);
      return;
    }
    console.log("Function response:", data);
    return data;
  } catch (error) {
    console.error("Unexpected error:", error);
  }
};

export const fetchSupabaseTable = async (date) => {
  const formattedDate = date.toISOString().split("T")[0];

  const { data: result, error } = await supabase
    .from(SUPABASE_CONFIG.TABLE_NAME)
    .select("*")
    .eq(SUPABASE_CONFIG.DATE_COLUMN, formattedDate);

  if (error) throw error;

  let senValue = 0;
  let mezaValue = 0;
  let mezbValue = 0;
  let lft_03_28 = 0;
  let lft_09_27 = 0;
  let lft_09_28 = 0;
  let soberano_ant = 0;



  result.forEach((rowData) => {
    if (rowData.description === "SENIOR") {
      senValue = rowData.value;
      console.log("PL SENIOR ANT", senValue);
    } else if (rowData.description === "MEZANINO A") {
      mezaValue = rowData.value;
      console.log("PL MEZ A ANT", mezaValue);
    } else if (rowData.description === "MEZANINO B") {
      mezbValue = rowData.value;
      console.log("PL MEZ B ANT", mezbValue);
    } else if (
      removeDuplicateSpaces(rowData.description) ===
      "LFT SEC TESOURO 100% SELIC (01/03/2028)"
    ) {
      lft_03_28 = rowData.value;
      console.log("lft_03_28: ", lft_03_28);
    } else if (
      removeDuplicateSpaces(rowData.description) ===
      "LFT SEC TESOURO 100% SELIC (01/09/2027)"
    ) {
      lft_09_27 = rowData.value;
      console.log("lft_09_27: ", lft_09_27);
    } else if (
      removeDuplicateSpaces(rowData.description) ===
      "LFT SEC TESOURO 100% SELIC (01/09/2028)"
    ) {
      lft_09_28 = rowData.value;
      console.log("lft_09_28: ", lft_09_28);
    } else if (
      removeDuplicateSpaces(rowData.description) ===
      "ITAÚ SOBERANO RENDA FIXA SIMPLES FIC DE FI"
    ) {
      soberano_ant = rowData.value;
      console.log("soberano_ant: ", soberano_ant);
    }
    
  });

  return [
    result,
    senValue,
    mezaValue,
    mezbValue,
    lft_03_28,
    lft_09_27,
    lft_09_28,
    soberano_ant
  ];
};
