import * as XLSX from "xlsx";
import { convertDateStringtoYYYYMMDD } from "./utils"; // Make sure to update the path
import { callSupabaseFunction } from "./supabaseClient";

export const getCDI = async (date) => {
  const dateForIndice = await callSupabaseFunction("fn_add_days", {
    from_date: date,
    days: -2,
  });
  const CDI =
    (await callSupabaseFunction("fn_get_indice", {
      ind: "CDI",
      dt: dateForIndice,
    })) / 100;

  return CDI;
};

export const organizarCarteira = async (worksheet) => {
  const fundCell = worksheet["A3"];
  const dataCell = worksheet["B3"];

  const fund = fundCell ? fundCell.v : "Fund not found";
  const spreadsheetDate = dataCell
    ? convertDateStringtoYYYYMMDD(dataCell.v)
    : "Date not found";
  const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  const findHeaderRow = (sheet, headerValue) => {
    for (let i = 0; i < sheet.length; i++) {
      if (sheet[i].includes(headerValue)) {
        return i;
      }
    }
    return null;
  };

  const estoqueHeaderRow = findHeaderRow(jsonSheet, "Tipo Produto");
  const provisoesHeaderRow = findHeaderRow(jsonSheet, "Provisão");
  const resumoHeaderRow = findHeaderRow(jsonSheet, "Carteira");

  if (
    estoqueHeaderRow === null ||
    provisoesHeaderRow === null ||
    resumoHeaderRow === null
  ) {
    throw new Error("Header rows not found");
  }

  const mapHeaders = (headerRow) => {
    const headerMap = {};
    headerRow.forEach((header, index) => {
      headerMap[header] = index;
    });
    return headerMap;
  };

  const estoqueHeaders = mapHeaders(jsonSheet[estoqueHeaderRow]);
  const provisoesHeaders = mapHeaders(jsonSheet[provisoesHeaderRow]);
  const resumoHeaders = mapHeaders(jsonSheet[resumoHeaderRow]);

  const extractEstoqueData = (startRow) => {
    const data = [];
    for (let i = startRow; i < jsonSheet.length; i++) {
      const row = jsonSheet[i];
      if (
        row &&
        Object.values(row).some(
          (value) => value !== null && value !== undefined
        )
      ) {
        const rowData = {
          fund,
          data: spreadsheetDate,
          tipo: row[estoqueHeaders["Tipo Produto"]] || "",
          description: row[estoqueHeaders["Ativo"]] || "",
          quant: row[estoqueHeaders["Quantidade"]] || 0,
          PU: row[estoqueHeaders["PU Atual"]] || 0,
          value: row[estoqueHeaders["Saldo atual"]] || 0,
        };
        data.push(rowData);
      } else {
        break;
      }
    }
    return data;
  };

  const extractProvisoesData = (startRow) => {
    const data = [];
    for (let i = startRow; i < jsonSheet.length; i++) {
      const row = jsonSheet[i];
      if (
        row &&
        Object.values(row).some(
          (value) => value !== null && value !== undefined
        )
      ) {
        const rowData = {
          fund,
          data: spreadsheetDate,
          tipo: "Provision",
          description: row[provisoesHeaders["Provisão"]] || "",
          quant: row[provisoesHeaders["Saldo Atual"]] || 0,
          PU: 1,
          value: row[provisoesHeaders["Saldo Atual"]] || 0,
        };
        data.push(rowData);
      } else {
        break;
      }
    }
    return data;
  };

  const extractResumoData = (startRow) => {
    const data = [];
    for (let i = startRow; i < jsonSheet.length; i++) {
      const row = jsonSheet[i];
      if (
        row &&
        Object.values(row).some(
          (value) => value !== null && value !== undefined
        )
      ) {
        const rowData = {
          fund,
          data: spreadsheetDate,
          tipo: "Tranche",
          description: row[resumoHeaders["Carteira"]] || "",
          quant: row[resumoHeaders["Quant.Cotas"]] || 0,
          PU: row[resumoHeaders["Valor da Cota"]] || 0,
          value: row[resumoHeaders["Patrimônio Atual"]] || 0,
        };
        data.push(rowData);
      } else {
        break;
      }
    }

    return data;
  };

  const rows = [
    ...extractEstoqueData(estoqueHeaderRow + 1),
    ...extractProvisoesData(provisoesHeaderRow + 1),
    ...extractResumoData(resumoHeaderRow + 1),
  ];

  return [rows, spreadsheetDate];
};
