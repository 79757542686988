import React, { useState, useEffect } from "react";
import "./App.css";
import Modal from "react-modal";
import DatePickerField from "./datepickerfield";
import logo from "./assets/tera_logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SUPABASE_CONFIG, COLUMN_NAMES, N8N_ENDPOINT } from "./config";
import * as XLSX from "xlsx";
import { supabase, fetchSupabaseTable } from "./supabaseClient";
import {
  calculateDifference,
  buscarUltimaCarteiraBatida,
  formatNumber,
  getExcelContent,
  removeDuplicateSpaces,
} from "./utils";
import { getCDI, organizarCarteira } from "./carteira";
import { organizarExtrato } from "./extrato";
import emailjs from "emailjs-com";
import axios from "axios";
import LoginWithMicrosoft from "./Login";

Modal.setAppElement("#root");

const App = () => {
  const [session, setSession] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [date, setDate] = useState(null);
  const [data, setData] = useState([]);
  const [carteiraData, setCarteiraData] = useState([]);
  const [extratoData, setExtratoData] = useState([]);
  const [saldo, setSaldo] = useState({});
  const [soberanoNet, setSoberanoNet] = useState({});
  const [valoresIdentificar, setValoresIdentificar] = useState();
  const [lastUpdated, setLastUpdated] = useState("");
  const [nextDataCarteira, setNextDataCarteira] = useState("");
  const [dataCarteira, setDataCarteira] = useState();
  const [taxaComissaoSupabase, setTaxaComissaoSupabase] = useState();
  const [taxaTransfSupabase, setTaxaTransfSupabase] = useState();
  const [dateAnoMesDia, setDateAnoMesDia] = useState();
  const [plAnt, setPlant] = useState({
    sen: 0,
    meza: 0,
    mezb: 0,
    lft_03_28: 0,
    lft_09_27: 0,
    lft_09_28: 0,
  });
  const [cdi, setCdi] = useState();

  const [isCarteiraUploaded, setIsCarteiraUploaded] = useState(false);
  const [isExtratoUploaded, setIsExtratoUploaded] = useState(false);
  const [isVIUploaded, setIsVIUploaded] = useState(false);
  const [allFilesUploaded, setAllFilesUploaded] = useState(false);
  const [valorComissaoProvisaoDebit, setValorComissaoProvisaoDebit] =
    useState(0);
  const [valorTransfProvisaoDebit, setValorTransfProvisaoDebit] = useState(0);
  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "300px", // Adjust width as needed
      padding: "20px",
      borderRadius: "10px",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  useEffect(() => {
    if (supabase) {
      supabase.auth
        .getSession()
        .then(({ data: { session } }) => setSession(session));
    }
  }, []);

  useEffect(() => {
    buscarUltimaCarteiraBatida().then(([latestDate, nextcarteira]) => {
      setLastUpdated(latestDate); // Update state with the latest date
      setNextDataCarteira(nextcarteira);
    });
  }, []);

  useEffect(() => {
    if (!date) return;

    fetchSupabaseTable(date)
      .then(
        ([
          result,
          senValue,
          mezaValue,
          mezbValue,
          lft_03_28,
          lft_09_27,
          lft_09_28,
          soberano_ant,
        ]) => {
          toast.success("Carteira anterior baixada!");
          setData(result);
          setPlant({
            sen: senValue,
            meza: mezaValue,
            mezb: mezbValue,
            lft_03_28: lft_03_28,
            lft_09_27: lft_09_27,
            lft_09_28: lft_09_28,
            soberano_ant: soberano_ant,
          });
          window.supabaseData = result;
        }
      )
      .catch((error) => toast.error(error));
  }, [date]);

  useEffect(() => {
    if (isCarteiraUploaded && isExtratoUploaded && isVIUploaded) {
      setAllFilesUploaded(true);
    }
  }, [isCarteiraUploaded, isExtratoUploaded, isVIUploaded]);

  useEffect(() => {
    const fetchSumOfComissao = async () => {
      try {
        const { data: sumResult, error: sumError } = await supabase
          .from("direitos")
          .select("vl_comissao, vl_transf")
          .eq("created_at", dateAnoMesDia);

        if (sumError) {
          toast.error(`Error fetching sum: ${sumError.message}`);
        } else {
          const totalcomissao = sumResult.reduce(
            (acc, curr) => acc + curr.vl_comissao,
            0
          );
          const totalTransf = sumResult.reduce(
            (acc, curr) => acc + curr.vl_transf,
            0
          );
          setTaxaComissaoSupabase(-totalcomissao);
          setTaxaTransfSupabase(-totalTransf);
          console.log(`Total Comissão: ${totalcomissao}`);
          console.log(`Total Transf: ${totalTransf}`);
        }
      } catch (error) {
        toast.error(`Unexpected error: ${error.message}`);
      }
    };

    if (!date || !isExtratoUploaded) return;
    fetchSumOfComissao();
  }, [date, dateAnoMesDia, isExtratoUploaded]);

  useEffect(() => {
    if (allFilesUploaded) {
      calculateExpectedValues();
    }
  }, [allFilesUploaded]);

  const handleVIFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert the worksheet to JSON
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Assuming the first row is the header
      const headers = jsonData[0];
      const rows = jsonData.slice(1);

      // Find index of required columns
      const dateIndex = headers.indexOf("Data");
      const valoresIndex = headers.indexOf("Valores a Identificar");
      const liquidacaoIndex = headers.indexOf("Liquidação de Títulos");

      // Replace 'chosenDate' with the actual date you want to filter by
      const chosenDate = dataCarteira; // example date

      // Filter rows by chosen date and calculate sums
      let sumValores = 0;
      let sumLiquidacao = 0;

      rows.forEach((row) => {
        if (row[dateIndex] === chosenDate) {
          sumValores += parseFloat(row[valoresIndex]) || 0;
          sumLiquidacao += parseFloat(row[liquidacaoIndex]) || 0;
        }
      });

      // Calculate the new variable
      const VI = -sumValores + sumLiquidacao;
      setValoresIdentificar(VI);
      setIsVIUploaded(true);
      checkAllFilesUploaded();
      console.log("VI:", VI);
    };

    reader.readAsArrayBuffer(file);
  };

  const checkAllFilesUploaded = () => {
    if (isCarteiraUploaded && isExtratoUploaded && isVIUploaded) {
      setAllFilesUploaded(true);
    }
  };

  const getTotal = (array, key, typeKey, typeValue) => {
    return array
      .reduce(
        (acc, obj) =>
          obj[typeKey] === typeValue ? acc + parseFloat(obj[key] || 0) : acc,
        0
      )
      .toFixed(2);
  };

  const checkExistingData = async () => {
    try {
      // Check if "data" column contains the value in nextDataCarteira
      const { data: existingData = [], error: selectError } = await supabase
        .from(SUPABASE_CONFIG.TABLE_NAME)
        .select("*")
        .eq(SUPABASE_CONFIG.DATE_COLUMN, dateAnoMesDia);

      if (selectError) {
        throw selectError;
      }

      if (existingData.length > 0) {
        setIsModalOpen(true);
        return;
      }

      // Proceed with data upload if no existing data is found
      subirTabelasSupabase();
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      console.error("Error:", error);
    }
  };

  const subirTabelasSupabase = async (replace = false) => {
    try {
      if (replace) {
        // Delete data from the first table
        const { error: deleteError1 } = await supabase
          .from(SUPABASE_CONFIG.TABLE_NAME)
          .delete()
          .eq(SUPABASE_CONFIG.DATE_COLUMN, dateAnoMesDia);

        if (deleteError1) {
          throw deleteError1;
        }

        // Delete data from the second table
        const { error: deleteError2 } = await supabase
          .from(SUPABASE_CONFIG.EXTRATO_NAME)
          .delete()
          .eq("date", dateAnoMesDia);

        if (deleteError2) {
          throw deleteError2;
        }
      }

      // Prepare and insert data into the first table
      const cleanedCarteiraData = carteiraData.map(
        ({ expectedValue, difference, ...rest }) => rest
      );

      const { error: error1 } = await supabase
        .from(SUPABASE_CONFIG.TABLE_NAME)
        .insert(cleanedCarteiraData);

      if (error1) {
        throw error1;
      }

      // Prepare and insert data into the second table
      const { error: error2 } = await supabase
        .from(SUPABASE_CONFIG.EXTRATO_NAME)
        .insert(extratoData);

      if (error2) {
        throw error2;
      }

      toast.success("Upload feito com sucesso!");
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      console.error("Error:", error);
    } finally {
      setIsModalOpen(false);
    }
  };

  emailjs.init(SUPABASE_CONFIG.API_EMAILJS);

  const enviarEmail = async () => {
    try {
      const response = await axios.post(N8N_ENDPOINT.URL, {
        tableHTML: carteiraData,
      });

      if (response.status === 200) {
        toast.success("Email enviado com sucesso!");
      } else {
        toast.error("Failed to send email.");
      }
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email.");
    }
  };

  const reset = async () => {
    window.location.reload();
  };

  const calculateExpectedValues = () => {
    const sumAtual = carteiraData
      .filter((row) => row.tipo !== "Tranche")
      .reduce((sum, row) => sum + row.value, 0);

    const tranchesAtual = carteiraData
      .filter((row) =>
        ["SENIOR", "MEZANINO A", "MEZANINO B"].includes(row.description)
      )
      .reduce((sum, row) => sum + row.value, 0);

    const subAtual = sumAtual - tranchesAtual;
    console.log("Sub Atual:", subAtual);

    const sumAnt = window.supabaseData
      .filter((row) => row.tipo !== "Tranche")
      .reduce((sum, row) => sum + row.value, 0);

    const tranchesAnt = window.supabaseData
      .filter((row) =>
        ["SENIOR", "MEZANINO A", "MEZANINO B"].includes(row.description)
      )
      .reduce((sum, row) => sum + row.value, 0);

    const subAnt = sumAnt - tranchesAnt;
    console.log("Sub Ant:", subAnt);

    const diffSub = subAtual - subAnt;
    carteiraData.forEach((row, index) => {
      const cdi_dia = (1 + cdi) ** (1 / 252) - 1;
      let expectedValue = null;
      if (row.description === "C/C ABC") {
        expectedValue = saldo.abc;
      } else if (row.description.trim() === "C/C BANCO DO BRASIL") {
        expectedValue = saldo.bb;
      } else if (row.description === "C/C BANVOX") {
        expectedValue = saldo.banvox;
      } else if (row.description === "FITBANK (PAGAMENTOS)") {
        expectedValue = saldo.fitbank;
      } else if (
        removeDuplicateSpaces(row.description) ===
        "LFT SEC TESOURO 100% SELIC (01/03/2028)"
      ) {
        expectedValue = plAnt.lft_03_28 * cdi_dia;
      } else if (
        removeDuplicateSpaces(row.description) ===
        "LFT SEC TESOURO 100% SELIC (01/09/2027)"
      ) {
        expectedValue = plAnt.lft_09_27 * cdi_dia;
      } else if (
        removeDuplicateSpaces(row.description) ===
        "LFT SEC TESOURO 100% SELIC (01/09/2028)"
      ) {
        expectedValue = plAnt.lft_09_28 * cdi_dia;
      } else if (
        row.description.includes("Outros") ||
        row.description.includes("Outras")
      ) {
        expectedValue = 0;
      } else if (row.description === "MEZANINO B") {
        const rend_dia = (1 + 0.08) ** (1 / 252) - 1;
        expectedValue = plAnt.mezb * ((1 + cdi_dia) * (1 + rend_dia) - 1);
      } else if (row.description === "MEZANINO A") {
        const rend_dia = (1 + 0.05) ** (1 / 252) - 1;
        expectedValue = plAnt.meza * ((1 + cdi_dia) * (1 + rend_dia) - 1);
      } else if (row.description === "SENIOR") {
        const rend_dia = (1 + 0.03) ** (1 / 252) - 1;
        expectedValue = plAnt.sen * ((1 + cdi_dia) * (1 + rend_dia) - 1);
      } else if (row.description.includes("Valores á Identificar")) {
        expectedValue = valoresIdentificar;
      } else if (row.description.includes("Taxa de Comissão")) {
        expectedValue = taxaComissaoSupabase + valorComissaoProvisaoDebit;
      } else if (row.description.includes("Taxa de Gestão")) {
        expectedValue = -2603.33;
      } else if (row.description.includes("Taxa ANBID")) {
        expectedValue = -32.77;
      } else if (row.description.includes("Taxa de verificação de lastro ")) {
        expectedValue = -65.22;
      } else if (row.description.includes("Taxa de Administração")) {
        expectedValue = -730.2;
      } else if (row.description.includes("Taxa de Custódia")) {
        expectedValue = -492.88;
      } else if (row.description.includes("Taxa de Controladoria")) {
        expectedValue = -492.88;
      } else if (row.description.includes("Taxa de Escrituração")) {
        expectedValue = -109.53;
      } else if (row.description.includes("Taxa de Transferência")) {
        console.log("valorTransfProvisaoDebit", valorTransfProvisaoDebit);
        expectedValue = taxaTransfSupabase + valorTransfProvisaoDebit;
      } else if (row.description.includes("SOBERANO")) {
        console.log("valornetsoberano: ", setSoberanoNet);
        expectedValue = plAnt.soberano_ant * cdi_dia + soberanoNet;
      } else if (row.description.includes("SUBORDINADA")) {
        expectedValue = diffSub;
      }
      row.expectedValue = expectedValue && expectedValue.toFixed(2);
    });
    setCarteiraData([...carteiraData]); // Trigger re-render with updated expected values
  };

  if (!session) return <LoginWithMicrosoft supabase={supabase} />;

  return (
    <div className="App">
      <img src={logo} alt="logo" width={110} height={30} />
      <div className="last-updated">Última Batida: {lastUpdated}</div>
      <div className="next-carteira">Próxima a bater: {nextDataCarteira}</div>
      <h1>BATIMENTO CARTEIRA FIDCs</h1>
      <div className="container">
        <DatePickerField
          label="Data"
          selected={date}
          onChange={(date) => setDate(date)}
        />

        <div className="file-upload">
          <label htmlFor="file-upload" className="custom-file-upload">
            Upload Carteira
          </label>
          <input
            id="file-upload"
            type="file"
            accept=".xlsx"
            onChange={(e) =>
              getExcelContent(e).then(async (excel) => {
                const [rows, spreadsheetDate] = await organizarCarteira(excel);
                rows.forEach((row) => {
                  row.difference = calculateDifference(row, data);
                });
                getCDI(spreadsheetDate).then(setCdi);
                setCarteiraData(rows);
                setIsCarteiraUploaded(true);
                checkAllFilesUploaded();
                toast.success("Carteira atual baixada!");
              })
            }
          />
        </div>

        <div className="file-upload">
          <label htmlFor="extrato-file-upload" className="custom-file-upload">
            Upload Extrato
          </label>
          <input
            id="extrato-file-upload"
            type="file"
            accept=".xlsx"
            onChange={(e) =>
              getExcelContent(e).then((excel) => {
                const transformedData = organizarExtrato(
                  excel,
                  setDataCarteira,
                  setDateAnoMesDia,
                  setValorComissaoProvisaoDebit,
                  valorComissaoProvisaoDebit,
                  setValorTransfProvisaoDebit,
                  setSaldo,
                  valorTransfProvisaoDebit,
                  setSoberanoNet
                );

                setExtratoData(transformedData);
                setIsExtratoUploaded(true);
                checkAllFilesUploaded();
                toast.success("Extrato baixado!");
              })
            }
          />
        </div>

        <div className="file-upload">
          <label
            htmlFor="valores-identificar-file-upload"
            className="custom-file-upload"
          >
            Upload Valores Identificar
          </label>
          <input
            id="valores-identificar-file-upload"
            type="file"
            accept=".xlsx"
            onChange={(e) => {
              handleVIFileUpload(e);
              toast.success("Valores identificar baixado!");
            }}
          />
        </div>

        {data.length > 0 && (
          <div className="supabase-data">
            <h2>Supabase Table</h2>
            <table>
              <thead>
                <tr>
                  {Object.keys(data[0]).map((key) => (
                    <th key={key}>{key}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    {Object.keys(row).map((key, i) => (
                      <td key={i}>
                        {key === COLUMN_NAMES.VALUE ||
                        key === COLUMN_NAMES.QUANTITY ||
                        key === COLUMN_NAMES.PU
                          ? formatNumber(row[key])
                          : row[key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td
                    colSpan={Object.keys(data[0]).length - 2}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    PL:
                  </td>
                  <td style={{ fontWeight: "bold" }}>
                    {formatNumber(
                      getTotal(
                        data,
                        COLUMN_NAMES.VALUE,
                        COLUMN_NAMES.TYPE,
                        "Tranche"
                      )
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        )}

        {carteiraData.length > 0 && (
          <div className="uploaded-data">
            <h2>Carteira</h2>
            <table>
              <thead>
                <tr>
                  <th>fund</th>
                  <th>data</th>
                  <th>tipo</th>
                  <th>description</th>
                  <th>quant</th>
                  <th>PU</th>
                  <th>value</th>
                  <th>difference</th>
                  <th>expected</th>
                </tr>
              </thead>
              <tbody>
                {carteiraData.map((row, index) => (
                  <tr key={index}>
                    <td>{row.fund}</td>
                    <td>{row.data}</td>
                    <td>{row.tipo}</td>
                    <td>{row.description}</td>
                    <td>{formatNumber(row.quant)}</td>
                    <td>{formatNumber(row.PU)}</td>
                    <td>{formatNumber(row.value)}</td>
                    <td
                      style={{
                        color:
                          row.difference >= 0
                            ? "green"
                            : row.difference < 0
                            ? "red"
                            : undefined,
                      }}
                    >
                      {row.difference !== null
                        ? formatNumber(row.difference)
                        : "N/A"}
                    </td>
                    <td
                      style={{
                        color:
                          row.expectedValue !== null &&
                          row.expectedValue !== "N/A" &&
                          !isNaN(row.expectedValue)
                            ? row.expectedValue >= 0
                              ? "green"
                              : "red"
                            : undefined,
                      }}
                    >
                      {row.expectedValue !== null
                        ? formatNumber(row.expectedValue)
                        : "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {extratoData.length > 0 && (
          <div className="extrato-data">
            <h2>Extrato</h2>
            <table>
              <thead>
                <tr>
                  <th>fund</th>
                  <th>date</th>
                  <th>account</th>
                  <th>description</th>
                  <th>debit</th>
                  <th>credit</th>
                  <th>balance</th>
                </tr>
              </thead>
              <tbody>
                {extratoData.map((row, index) => (
                  <tr key={index}>
                    <td>{row.fund}</td>
                    <td>{row.date}</td>
                    <td>{row.account}</td>
                    <td>{row.description}</td>
                    <td>{formatNumber(row.debit)}</td>
                    <td>{formatNumber(row.credit)}</td>
                    <td>{formatNumber(row.balance)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        {allFilesUploaded && (
          <button onClick={checkExistingData} className="button-spacing">
            Subir dados no banco
          </button>
        )}
        {allFilesUploaded && (
          <button onClick={enviarEmail} className="button-spacing">
            Enviar Email
          </button>
        )}
        {
          <button onClick={reset} className="button-spacing">
            Refresh Page
          </button>
        }
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          contentLabel="Confirm Replace Data"
          style={modalStyles}
        >
          <h2>Essa carteira já existe no banco.</h2>
          <p>Deseja substituir a carteira existente?</p>
          <button
            onClick={() => {
              subirTabelasSupabase(true);
              setIsModalOpen(false);
            }}
          >
            Sim
          </button>
          <button onClick={() => setIsModalOpen(false)}>Não</button>
        </Modal>
      </div>
    </div>
  );
};

export default App;
