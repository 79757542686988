import * as XLSX from "xlsx";
import { excelSerialDateToJSDate } from "./utils";

export const organizarExtrato = (
  worksheet,
  setDataCarteira,
  setDatayyymmdd,
  setValorComissaoProvisaoDebit,
  valorComissaoProvisaoDebit,
  setValorTransfProvisaoDebit,
  setSaldo,
  valorTransfProvisaoDebit,
  setSoberanoNet
) => {
  const fundCell = worksheet["A2"];
  let fund = fundCell ? fundCell.v : "Fund not found";

  if (fund.includes("FIDC")) {
    fund = fund.substring(fund.indexOf("FIDC"));
  }

  const dateCell = worksheet["A4"];
  const dateValue = dateCell
    ? excelSerialDateToJSDate(dateCell.v)
    : "Date not found";
  const jsonSheet = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

  let dateyyymmdd = dateValue;

  let datacarteira = dateCell.v;

  setDataCarteira(datacarteira);
  console.log("data:", datacarteira);

  setDatayyymmdd(dateyyymmdd);
  console.log("data yyyy-mm-dd:", dateyyymmdd);

  const accounts = [];
  jsonSheet.forEach((row, rowIndex) => {
    if (row[0] && row[0].toString().includes("Conta")) {
      accounts.push({ name: row[0], startRow: rowIndex });
    }
  });

  const transformedData = [];
  let saldoAnteriorFitbank = 0;
  let saldoAtualFitbank = 0;
  let saldoAnteriorABC = 0;
  let saldoAtualABC = 0;
  let saldoAnteriorBB = 0;
  let saldoAtualBB = 0;
  let saldoAnteriorItau = 0;
  let saldoAtualItau = 0;
  let saldoAnteriorCM = 0;
  let saldoAtualCM = 0;
  let saldoAnteriorBanvox = 0;
  let saldoAtualBanvox = 0;
  let soberanoDebitSum = 0;
  let soberanoCreditSum = 0;

  accounts.forEach((account, index) => {
    const startRow = account.startRow + 1;
    const endRow =
      index < accounts.length - 1
        ? accounts[index + 1].startRow
        : jsonSheet.length;

    let inDescriptionBlock = false;
    let currentDescription = [];
    let currentDebits = [];
    let currentCredits = [];
    let currentBalances = [];

    for (let i = startRow; i < endRow; i++) {
      const row = jsonSheet[i];

      if (row && row.length > 1) {
        if (row[2] === "Saldo Anterior") {
          inDescriptionBlock = true;
          currentDescription = [row[2]];
          currentDebits = [row[3] || 0];
          currentCredits = [row[4] || 0];
          currentBalances = [row[5] || 0];

          if (account.name.includes("FITBANK")) {
            saldoAnteriorFitbank = row[5] || 0;
          } else if (account.name.includes("ABC")) {
            saldoAnteriorABC = row[5] || 0;
          } else if (account.name.includes("C/C BANCO DO BRASIL")) {
            saldoAnteriorBB = row[5] || 0;
          } else if (account.name.includes("ITAÚ")) {
            saldoAnteriorItau = row[5] || 0;
          } else if (account.name.includes("CM CAPITAL")) {
            saldoAnteriorCM = row[5] || 0;
          } else if (account.name.includes("BANVOX")) {
            saldoAnteriorBanvox = row[5] || 0;
          }
        } else if (inDescriptionBlock) {
          currentDescription.push(row[2]);
          currentDebits.push(row[3] || 0);
          currentCredits.push(row[4] || 0);
          currentBalances.push(row[5] || 0);

          if (row[2].includes("SOBERANO")) {
            soberanoDebitSum += row[3] || 0;
            soberanoCreditSum += row[4] || 0;
          }

          if (
            row[2] ===
            "Valor Comissão - Provisão. (PARCELAS DE CONSORCIO A PAGAR)"
          ) {
            setValorComissaoProvisaoDebit(row[3] || 0);
            console.log("valor comissao extrato:", valorComissaoProvisaoDebit);
          }
          if (
            row[2] ===
            "taxa de transferência - Provisão. (PARCELAS DE CONSORCIO A PAGAR)"
          ) {
            setValorTransfProvisaoDebit(row[3] || 0);
            console.log("valor transf extrato:", valorTransfProvisaoDebit); // Store the debit value
          }

          if (row[2] === "Saldo Atual") {
            inDescriptionBlock = false;
            if (account.name.includes("FITBANK")) {
              saldoAtualFitbank = row[5] || 0;
            } else if (account.name.includes("ABC")) {
              saldoAtualABC = row[5] || 0;
            } else if (account.name.includes("C/C BANCO DO BRASIL")) {
              saldoAtualBB = row[5] || 0;
            } else if (account.name.includes("ITAÚ")) {
              saldoAtualItau = row[5] || 0;
            } else if (account.name.includes("CM CAPITAL")) {
              saldoAtualCM = row[5] || 0;
            } else if (account.name.includes("BANVOX")) {
              saldoAtualBanvox = row[5] || 0;
            }

            for (let j = 0; j < currentDescription.length; j++) {
              transformedData.push({
                fund,
                date: dateValue,
                account: account.name,
                description: currentDescription[j],
                debit: currentDebits[j],
                credit: currentCredits[j],
                balance: currentBalances[j],
              });
            }
          }
        }
      }
    }
  });

  setSaldo({
    fitbank: saldoAtualFitbank - saldoAnteriorFitbank,
    abc: saldoAtualABC - saldoAnteriorABC,
    bb: saldoAtualBB - saldoAnteriorBB,
    itau: saldoAtualItau - saldoAnteriorItau,
    cm: saldoAtualCM - saldoAnteriorCM,
    banvox: saldoAtualBanvox - saldoAnteriorBanvox,
  });

  const soberanoNet = soberanoDebitSum - soberanoCreditSum;
  setSoberanoNet(soberanoNet);
  console.log("soberanoDebit", soberanoDebitSum);
  console.log("soberanoCredit", soberanoCreditSum);
  console.log("soberanoNet", soberanoNet);

  return transformedData;
};
