import { Button, Flex } from "antd";
import { WindowsFilled } from "@ant-design/icons";
import BackgroundImage from "./assets/background-login.png";
import { Logo } from "./Logo";

export default function LoginWithMicrosoft({ supabase }) {
  const handleAzureLogin = async () => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "azure",
      options: {
        scopes: "email",
        redirectTo: window.location.href,
      },
    });
    if (error) {
      console.error("Error logging in with Azure:", error.message);
    }
  };

  return (
    <Flex
      gap={40}
      style={{
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url(" + BackgroundImage + ")",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      vertical
    >
      <Logo width={300} />
      <Button
        onClick={handleAzureLogin}
        type="primary"
        icon={<WindowsFilled />}
        style={{ width: 300, height: 50 }}
      >
        Login com Microsoft
      </Button>
    </Flex>
  );
}
