import { SUPABASE_CONFIG } from "./config";
import { supabase, callSupabaseFunction } from "./supabaseClient";
import * as XLSX from "xlsx";

export const buscarUltimaCarteiraBatida = async () => {
  try {
    const { data, error } = await supabase
      .from(SUPABASE_CONFIG.TABLE_NAME)
      .select(SUPABASE_CONFIG.DATE_COLUMN)
      .order(SUPABASE_CONFIG.DATE_COLUMN, { ascending: false })
      .limit(1);

    if (error) return console.error("Error fetching latest date:", error);

    const latestDate = data[0][SUPABASE_CONFIG.DATE_COLUMN];
    // setLastUpdated(latestDate); // Update state with the latest date
    let nextcarteira = await callSupabaseFunction("fn_add_days", {
      from_date: latestDate,
      days: 1,
    });

    return [latestDate, nextcarteira];
  } catch (error) {
    console.error("Unexpected error fetching latest date:", error);
  }
};

export const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
  }).format(number);
};

export const excelSerialDateToJSDate = (serial) => {
  const excelEpoch = new Date(Date.UTC(1900, 0, 1));
  const jsDate = new Date(
    excelEpoch.getTime() + (serial - 2) * 24 * 60 * 60 * 1000
  );

  const year = jsDate.getUTCFullYear();
  const month = String(jsDate.getUTCMonth() + 1).padStart(2, "0");
  const day = String(jsDate.getUTCDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const convertDateStringtoYYYYMMDD = (dateString) => {
  const [datePart] = dateString.split(" ");
  const [day, month, year] = datePart.split("/");
  return `${year}-${month}-${day}`;
};

export const calculateDifference = (carteiraRow, supabaseData) => {
  if (!carteiraRow || !supabaseData) {
    console.error(
      "Invalid input data for calculateDifference:",
      carteiraRow,
      supabaseData
    );
    return null;
  }

  const matchingSupabaseRow = supabaseData.find(
    (supabaseRow) =>
      supabaseRow.description === carteiraRow.description &&
      supabaseRow.tipo === carteiraRow.tipo
  );

  if (matchingSupabaseRow) {
    return (carteiraRow.value - matchingSupabaseRow.value).toFixed(2);
  }
  return null;
};

export const getExcelContent = (event) =>
  new Promise((resolve) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      resolve(worksheet);
    };
  });

export const removeDuplicateSpaces = (str) => {
  return str.replace(/\s+/g, " ").trim();
};
