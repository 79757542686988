// config.js
export const SUPABASE_CONFIG = {
  TABLE_NAME: 'batimento_carteiras_fidc',
  URL: 'https://xegwbbodrpkedyptlnko.supabase.co',
  API_KEY: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InhlZ3diYm9kcnBrZWR5cHRsbmtvIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NDkyNzY2OTcsImV4cCI6MTk2NDg1MjY5N30.Gw8Q7iQASepJcKPbdyIexB-Ln6-YO1kYRaKgxj6qVhs',
  DATE_COLUMN: 'data',
  API_EMAILJS: 'AiOpo1p8WVyemncsB',
  EXTRATO_NAME:'batimento_extratos_fidc'
};

export const COLUMN_NAMES = {
  QUANTITY: 'quant',
  PU: 'PU',
  VALUE: 'value',
  DATE: 'Data',
  DIFFERENCE: 'Difference',
  TYPE: 'tipo'
};

export const N8N_ENDPOINT = {
  URL: "https://teracapital.app.n8n.cloud/webhook/dd4bd2f6-7fb3-4563-aaab-d0d5b71aacba",
};

