import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePickerField = ({ label, selected, onChange }) => {
  return (
    <div>
      <label>{label}:</label>
      <DatePicker
        selected={selected}
        onChange={onChange}
        dateFormat="yyyy-MM-dd"
        placeholderText="yyyy-MM-dd"
      />
    </div>
  );
};

export default DatePickerField;
