export const Logo = ({
  width,
  height,
  marginRight,
  src = "https://iwluwlzcpkxhdjrcanka.supabase.co/storage/v1/object/public/assets/tera-logo-branco.png",
}) => {
  return (
    <img src={src} alt="Tera Logo" style={{ width, height, marginRight }} />
  );
};
